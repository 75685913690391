<template>
  <div class="topup-detail">
    <van-image
      width="100%"
      height="320px"
      :src="data.attach"
      @click="imagePreview"
    />
    <div class="topup-amount">
      <span class="topup-title"> 金额: </span>
      <span class="topup-value"> {{ data.totalForeignFee }}</span>
    </div>
    <van-field
      v-model="remark"
      rows="2"
      autosize
      label="备注:"
      type="textarea"
      style="font-size: 1rem"
      maxlength="50"
      placeholder="请输入备注"
      show-word-limit
    />

    <van-radio-group v-model="radio" direction="horizontal">
      <van-radio name="1" checked-color="#00C468">审核通过</van-radio>
      <van-radio name="2" checked-color="#00C468">审核不通过</van-radio>
    </van-radio-group>

    <div class="saveButton">
      <van-button
        @click="doSubmit"
        type="primary"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        确定
      </van-button>
    </div>
  </div>
</template>
<script>
import {
  Image as VanImage,
  ImagePreview,
  Toast,
  Field,
  RadioGroup,
  Radio,
  Button,
} from "vant";
import { topupDetail, topupConfirm } from "@/service/api";

export default {
  components: {
    [VanImage.name]: VanImage,
    [ImagePreview.name]: ImagePreview,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
  },
  data() {
    return {
      id: this.$route.query.id,
      data: {},
      remark: "",
      radio: "1",
      submitLoading: false,
    };
  },
  mounted() {
    this.topupDetail();
  },
  methods: {
    imagePreview() {
      ImagePreview([this.data.attach]);
    },
    async doSubmit() {
      const payload = {
        orderNo: this.id,
        status: this.radio === "1" ? 6 : 5,
        remark: this.remark,
      };

      this.submitLoading = true;
      const res = await topupConfirm(payload);
      if (res.success) {
        Toast.success(this.radio === "1" ? "审核通过" : "审核不通过");
        this.$router.go(-1);
      } else Toast.fail(res.message);

      this.submitLoading = false;
    },
    async topupDetail() {
      const res = await topupDetail(this.id);
      if (res.success) {
        const { data } = res;
        this.data = data;
        this.remark = data.remark;
      } else Toast.fail(res.message);
    },
  },
};
</script>
<style lang="less" scoped>
.topup-detail {
  text-align: center;
  padding: 15px;
  background-color: white;
}

.topup-amount {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  font-size: 1rem;
}

.topup-title {
}

.topup-value {
  flex: 1;
  display: inline-block;
  text-align: left;
  padding: 0px 10px;
}

::v-deep .van-field__label {
  width: auto;
}

.van-cell {
  padding: 0px;
}

.van-radio-group--horizontal {
  padding: 10px 0px;
}

.van-button--normal {
  border-radius: 5px;
  padding: 0px 40px;
}

.saveButton {
  margin: 15px 15px 0 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  text-align: center;
}
</style>